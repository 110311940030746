/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Deepak Mandal - Software developer",
  description: "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Deepak Kumar Mandal - Software developer",
    type: "website",
    url: "https://deepakmandal.dev/",
  },
};

//Home Page
const greeting = {
  title: "Deepak Kumar Mandal",
  logo_name: "Deepak Mandal",
  job_profile: "Full Stack Developer | Programmer",
  subTitle:
    "A passionate Full Stack Software Developer 🚀 having an experience of building Web and Mobile applications with JavaScript / Reactjs / Nodejs / Express and some other cool libraries and frameworks.",
  resumeLink: "https://drive.google.com/file/d/11GVYDtdMYys9d1IBzXd58IRX92XX3sk7",
  portfolio_repository: "https://github.com/dmandal1",
  githubProfile: "https://github.com/dmandal1",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/dmandal1",
  // linkedin: "https://www.linkedin.com/in/dmandal1/",
  // gmail: "contact@deepakmandal.dev",
  // gitlab: "https://gitlab.com/dmandal1",
  // facebook: "https://www.facebook.com/iamdeepakmandal/",
  // twitter: "https://twitter.com/iamdeepakmandal/",
  // instagram: "https://www.instagram.com/iamdeepakmandal/"
  {
    name: "Facebook",
    link: "https://www.facebook.com/iamdeepakmandal/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Twitter",
    link: "https://twitter.com/iamdeepakmandal",
    fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
    backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/iamdeepakmandal/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/dmandal1/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:me@deepakmandal.dev",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Github",
    link: "https://github.com/dmandal1",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React-Redux",
        "⚡ Creating application backend in Node and Express",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#7952B3",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0769AD",
          },
        },
        {
          skillName: "SASS",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#5FA04E",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#589636",
          },
        },
        {
          skillName: "ExpressJs",
          fontAwesomeClassname: "simple-icons:express",
          style: {
            color: "#303030",
          },
        },
        {
          skillName: "Redis",
          fontAwesomeClassname: "simple-icons:redis",
          style: {
            color: "#FF4438",
          },
        },
        {
          skillName: "Postman",
          fontAwesomeClassname: "simple-icons:postman",
          style: {
            color: "#FF6C37",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#f34f29",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#181717",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "#001732",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#310000",
          },
        },
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#450135",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Canva",
          fontAwesomeClassname: "simple-icons:canva",
          style: {
            color: "#00C4CC",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/dmandal1",
    },
    {
      siteName: "GeeksForGeeks",
      iconifyClassname: "simple-icons:geeksforgeeks",
      style: {
        color: "#2F8D46",
      },
      profileLink: "https://auth.geeksforgeeks.org/user/dmandal/",
    },
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#FFA116",
      },
      profileLink: "https://leetcode.com/dmandal1/",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/dmandal148",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "https://codeforces.com/profile/dmandal1",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Dr. A.P.J. Abdul Kalam Technical University",
      subtitle: "B.Tech. In Computer Engineering",
      logo_path: "uptu_logo.png",
      alt_name: "AKTU University",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ CGPA: 7.08/10",
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CN, AI etc.",
        "⚡ Apart from this, I have done courses on Java, Python, ReactJs and Full Stack Development.",
      ],
      website_link: "https://aktu.ac.in/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "JavaScript (Basic) Certificate",
      subtitle: "- HackerRank",
      logo_path: "hackerrank_logo.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/ce2b03aeef00",
      alt_name: "HackerRank",
      color_code: "#050C18",
    },
    {
      title: "Node (Basic) Certificate",
      subtitle: "- HackerRank",
      logo_path: "hackerrank_logo.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/b73d3c6e4399",
        alt_name: "HackerRank",
        color_code: "#050C18",
    },  
    {
      title: "Digital Marketing",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1X_pTvQk7IsGPyu3eKDPqlfCr1Hy8zflZ",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Introduction to JAVA",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://certificate.codingninjas.com/verify/c3a8bd1f9268f956",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    {
      title: "Data Structures in JAVA",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://certificate.codingninjas.com/verify/b2057ba196e759c5",
        alt_name: "Coding Ninjas India",
        color_code: "#E7E7E7",
    },
    {
      title: "Nasscom Python",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://certificate.codingninjas.com/verify/f1ec762b06a7a01c",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    {
      title: "Open Source development",
      subtitle: "- GirlScript Foundation",
      logo_path: "girlscript_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1yIZL1tWups3RRjECsBPJLsn1Gpsil3Tn",
      alt_name: "Coursera",
      color_code: "#E54B20",
    },
    {
      title: "Front End | Web development",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://www.codingninjas.in/verify/3bea56d89b10436d",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    {
      title: " Back End with Node.js",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://www.codingninjas.in/verify/809b6ed726ce9083",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    {
      title: "Advance Front-End with React",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link:
        "https://students.codingninjas.com/verify/2c0effd81a77c37c",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    
    {
      title: "Database Management System",
      subtitle: "- Coding Ninjas India",
      logo_path: "cn_logo.png",
      certificate_link: "https://certificate.codingninjas.com/view/8ca10e7f3f257285",
      alt_name: "Coding Ninjas India",
      color_code: "#E7E7E7",
    },
    {
      title: "HTML5 & CSS3 Fundamentals",
      subtitle: "- Microsoft",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/1cmUaRdRCF-8DzWFdXKNBQm8Phdgb2F_6",
      alt_name: "Microsoft",
      color_code: "#E18767",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as Frontend and Backend Developer, Designer and Software Architect. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Backend Developer",
          company: "Stulink Pvt. Ltd.",
          company_url: "https://stulink.com/",
          logo_path: "stulink_logo.png",
          duration: "Mar 2024 - Present",
          location: "Remote, India",
          description:
            "I'm Working as a backend developer on a running projects of Stulink,  using JavaScript, Node.js, Express, MongoDB, Redis, and AWS S3",
          color: "#279605",
        },
        {
          title: "Associate Software Developer",
          company: "Cloudcraftz Solutions Pvt. Ltd.",
          company_url: "https://www.cloudcraftz.com/",
          logo_path: "cc_logo.png",
          duration: "Oct 2021 - Feb 2024",
          location: "Karnataka, Bangalore, India",
          description:
            "I have worked as a backend developer on a running projects of Cloudcraftz Solutions, using tech stacks like JavaScript, NodeJS, Express Js and MongoDB.",
          color: "#0879bf",
        },
        {
          title: "Software Developer Trainee",
          company: "Cloudcraftz Solutions Pvt. Ltd.",
          company_url: "https://www.cloudcraftz.com/",
          logo_path: "cc_logo.png",
          duration: "Apr 2021 - Sep 2021",
          location: "Karnataka, Bangalore, India",
          description:
            "I have Worked as a Software developer, using tech stacks like Java, MySQL, SQL, HTML, CSS, JavaScript etc. at Cloudcrafz Solutions.",
          color: "#9b1578",
        },
        {
          title: "Teaching Assistant",
          company: "Coding Ninjas, New Delhi, India",
          company_url: "https://www.codingninjas.com/",
          logo_path: "cn_logo.png",
          duration: "Dec 2019 - Apr 2020",
          location: "New Delhi, India",
          description: "Worked as a Teaching Assistant for the online batches of Career Camp (Full Stack Web Development with Node js) at Coding Ninjas. where I solved students doubts as well as helped students to build projects.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Web developer Intern",
          company: "Coding Ninjas India",
          company_url: "https://www.codingninjas.com/",
          logo_path: "cn_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "New Delhi, Inida",
          description:
            "I have worked on project of Web development using tech stacks like JavaScript, NodeJs, Reactjs, Express and MongoDB.",
          color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Coding Ninjas Campus Ambassador",
          company: "Coding Ninjas, New Delhi, India",
          company_url: "https://www.codingninjas.com/",
          logo_path: "cn_logo.png",
          duration: "April 2019 - April 2020",
          location: "New Delhi, India",
          description:
            "Coding Ninjas Campus Ambassador is a program for college/university students to lead the awareness and use of coding/programming of their courses, events and bootcamps. Under this program, I have organised hands on workshops, hackathons and seminars to teach Coding and programming concepts to students.",
          color: "#4285F4",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "deepak_mandal.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with JavaScript, NodeJs, React, Express, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://deepakmandal.dev/blog",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Noida, India - 201301",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/Cp4tx2eiYvHeXvDN9",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+91-9504243148",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
